import React from 'react'

function Story() {
  return (
    <div className='container-fluid'>
    <div className="row">
      <div className="col title-color">
        <h1>Our Story</h1>
      </div>
    </div>
    <div className="row my-5">
      <div className="col-12 text-center" data-aos="zoom-in" data-aos-duration='3000'>
      <h2 className='text-uppercase mb-4'>Welcome to skin Vention</h2>
      <div className='fs-4' style={{letterSpacing:'0.5px'}}>
      <p >Your Ultimate Destination for SKin and Hair Care Solutions in india !</p>
        <p> Sneha is a cosmetologist who focuses on treating various skin issues, <br/>
        including dark spots, hyperpigmentation, aging signs, and scars. We know everyone is different, so <br/> we provide personalized treatments to achieve the best
        results. </p>
        <p>Tailor SKin care, Hydra Facial , Antiaging, Hair (Dandruff, Hair Growth, All  Types of Scalp <br/> Treatment) , Hight Frequency & Radial  Frequency 
        Treatment, Acne,<br/> Hyperpigmentation and uneven skin tone & Body Care Treatment. </p> 
      </div>
      </div>
    </div>
     
    </div>
  )
}

export default Story
