import React from 'react'

function Offer() {
  return (
    <div className='container-fluid'>
    <div className="row  ">
      <div className="col title-color ">
        <h1>Skinvention Offers</h1>
      </div>
    </div>
    <div className="row py-5" >
      <div className="col-12 col-md-6 col-lg-2  mb-3 centered" data-aos='zoom-in'>
        <img src="media\Images\Group.png" alt="" className='m-2  m-md-0 mx-md-0 offer_img centered'/>
        <h3 className='mt-md-4 mt-6 text-center'>Hydra Facial</h3>
      </div>
      <div className="col-12 col-md-6 col-lg-2 mb-3  centered"  data-aos='zoom-in'>
        <img src="media\Images\Group23.png" className='img-fluid m-3  m-md-0 mx-md-0 offer_img centered
' alt="" />
        <h3 className='mt-md-4 mt-6 text-center'>Acne Treatment</h3>
      </div>
      <div className="col-12 col-md-6 col-lg-2 mx-md-1 mb-3 centered"  data-aos='zoom-in'>
      <img src="media\Images\Group25.png" className='img-fluid m-3  m-md-0 mx-md-0 offer_img centered
' alt="" />
      <h3 className='mt-md-4 mt-6 text-center'>Anti Aging High Frequency</h3>
      </div>
      <div className="col-12 col-md-6 col-lg-2 mb-3 centered"  data-aos='zoom-in'>
      <img src="media\Images\Group24.png" className='img-fluid  m-3 m-md-0 mx-md-0 offer_img centered
' alt="" />
      <h3 className='mt-md-4 mt-6 text-center'>Skin & Hair Consultation</h3>
      </div>
      <div className="col-12 col-md-6 col-lg-2 mb-3 centered"  data-aos='zoom-in'>
      <img src="media\Images\Group26.png" className='img-fluid m-3 m-md-0 mx-md-0 offer_img centered
' alt=""  />
      <h3 className='mt-md-4 mt-6 text-center'>Hyper Pigmentation</h3>
      </div>
    </div>
    </div>
  )
}

export default Offer
