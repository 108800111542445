import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Homepage from './landingPage/home/Homepage';
import ServicePage from './landingPage/services/ServicePage';
import AboutPage from './landingPage/about/AboutPage';
import ContactPage from './landingPage/contact/ContactPage';
// import NavbarPage from './landingPage/NavbarPage.js';
import Navbar2 from './landingPage/Navbar2';
// import Navbarb from './landingPage/Navbarb';
import Footer from './landingPage/Footer';
import './index.css';
import NotFound from './landingPage/NotFound';
import AppoinmentPage from './landingPage/BookAppoinment/AppoinmentPage';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <BrowserRouter>
   {/* <NavbarPage/> */}
   <Navbar2/>

<Routes>
  <Route path="/" element={<Homepage />} />
  <Route path="/services" element={<ServicePage />} />
  <Route path="/about" element={<AboutPage />} />
  <Route path="/contact" element={<ContactPage />} />
  <Route path='/appointment' element={<AppoinmentPage/>}/>
  <Route path="/*" element={<NotFound/>} />
</Routes>
 <Footer/>
 </BrowserRouter>
);


