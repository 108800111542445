import React from 'react'

function Founder() {
  return (
    <div className='container-fluid'>
    <div className="row">
      <div className="col title-color">
        <h1>What Founder says</h1>
      </div>
    </div>
    <div className="row my-5">
    <div className="col-12 col-lg-4 " data-aos="flip-right">
        <img src="media/Images/Founder.png" alt="Founder" style={{width:'80%'}} className='centered'/> 
      </div>
      <div className="col-12 col-lg-8 order-md-first">
        <p className='my-5 fs-3 ps-4 text-muted'>Welcome to Skin Vention I'm excited to have you here and to share our vision for innovative and effective skincare solutions. Our journey began with a passion for combining advanced science with personalized care to address unique skin concerns. At Skin Vention, we are dedicated to offering treatments that not only enhance your natural beauty but also promote long-term skin health. Each step of out  process is designed with you in mind, aiming to provide a rejuvenating experience tailored to your needs. Thank you for trusting us with your skincare goals. We look forward to being a part of your beauty journey.</p>
      </div>
    
    </div>
     
    </div>
  )
}

export default Founder
