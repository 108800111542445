
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Validation.js';

function Form() {
  const form = useRef();

  
  const sendEmail = (e) => {
    e.preventDefault();

    
    emailjs
      .sendForm('service_htmr4eg', 'template_vnorfap', form.current, {
        publicKey: '84mShfAwaaK_zMnU3',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className='container-fluid'>
     <div className="row">
      <div className="col-12 title-color">
        <h1 >Connect With Us</h1>
      </div>
    </div> 
    <div className="row">
      <form action="" onSubmit={sendEmail} class="needs-validation" novalidate>
        <div className="col-lg-6 col-12 rounded-3 mx-auto p-5 m-3" style={{backgroundColor:'#f5d3e1'}}>
          <h2 className='mb-4'>You Will hear from us at the earliest !</h2>
          <div>
          <input type="text" class="form-control my-3 p-3" placeholder="Full Name" name='user_name' aria-label="Full Name" aria-describedby="basic-addon1" required/>
          <div class="invalid-feedback">
        You must enter your name
      </div>
          </div>
         
         <div>
         <input type="email" class="form-control my-3 p-3" placeholder="Email" aria-label="Email" name='user_email' aria-describedby="basic-addon1" required/>
         <div class="invalid-feedback">
        You must enter your email
      </div>
         </div>
          
          <div>
          <input type="Number" class="form-control my-3 p-3" placeholder="phone" aria-label="phone" name='user_phone' aria-describedby="basic-addon1" required/>
          <div class="invalid-feedback">
        You must enter your phone
      </div>
          </div>
          <div>
          <input type="text" class="form-control my-3 p-3" placeholder="City" aria-label="city" name='city' aria-describedby="basic-addon1" required/>
          <div class="invalid-feedback">
        You must enter your city
      </div>
          </div>

         <div>
         <textarea class="form-control my-3 p-3" cols="23" rows="5" aria-label="With textarea" name='message' placeholder='Any Comment or query'></textarea>
         </div>
         
         <div class="d-grid">
         {/* <button class="btn btn-primary" type="submit">Send</button> */}
  <a class="btn  p-3 fw-bold" href='mailto:skinventioninquiry@gmail.com' type="button" style={{backgroundColor: '#fe539a'}}>Send</a>
  
  <p className='small text-center mt-3'>You can also contact us at <span className='contact'> +91 8010689334 </span></p>
</div>
        </div>
      </form>
    </div>
    </div>
  )
}

export default Form
