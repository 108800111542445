import React from 'react'
import Sbanner from './Sbanner';
import LeftServices from './LeftServices';
import RightServices from './RightServices';
import Consultation from '../Consultation';

function ServicePage() {
  return (
    <div>
      <Sbanner/>
      <LeftServices ImageURL="media/Images/Group.png" title="Hydra Facial" description="A HydraFacial  is a gentle  and effective  treatment that cleanses, exfoliates, and hydrates your skin. It removes impurities
            while nourishing with mosturizers, leaving your face refreshed, smooth, and radiant." />
      <RightServices ImageURL="media/Images/Group23.png" title="Acne Treatment" description="Acne treatment targets and clears pimples and blemishes, reducing inflammation and preventing future breakouts
      . It improves skin texture and promotes a clearer, heathier complexion with tailored solutions for each skin type."/>
      <LeftServices ImageURL="media/Images/Group25.png" title="Anti Aging High Frequency" description="Anti-aging high frequency treatments use gentle electrical currents to stimulate
      collegen production, improve blood circulation, and tighten skin. This reduces fine lines, wrinkles, and enhaces overall skin tone and texture."/>
    <RightServices ImageURL="media/Images/Group24.png" title="Skin & Hair Consultation" description="Skin & Hair consultations provide personalized advice & solutions for improving skin and hair health.
    Experts analyze your unique needs, offering tailored recommendations to achieve your desired texture and apperance."/>

    <LeftServices ImageURL="media/Images/Group26.png" title="Hyper Pigmentation" description="Hyper pigmentation treatments target dark spots & uneven skin tone caused by sun exposure, acne scars, or hormonal
    changes.They use specialized products and procedures to lighten and even out pigmentation for clearer skin.
    "/>
    <Consultation/>

    
    </div>
  )
}

export default ServicePage
