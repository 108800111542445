import React from 'react';
import Banner from './Banner';
import Founder from './Founder';
import Choose from './Choose';
import Offer from './Offer';
import Treatment from './Treatment';
import Review from './Review';
import Consultation from '../Consultation';
function Homepage() {
  return (
    <>
    
      <Banner/>
      <Offer/>
      <Treatment/>
      <Founder/>
      <Choose/>
      <Review/>
      <Consultation/>
     
    </>
  )
}

export default Homepage
