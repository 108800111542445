import React from 'react'

function Sbanner() {
  return (
    // <div className='container-fluid'>
    // <div className="row ps-3">
    //     <div className="col-lg-7 d-flex flex-column justify-content-center">
    // <h1 className='fs-1 text-uppercase fw-bolder text-black'>Transforming SKin, Enhancing, beauty</h1>
    // <p className='fs-2 px-5 custom-weight'>Advanced Care for Heathier Skin</p>
    //     </div>
    //     <div className="col-lg-5">
    //         <img src='media\Images\aboutbanner.png' alt='GroupImg' style={{width:'100%', height:'100%'}}/>
    //     </div>
    // </div>

    // <div className="row">
    //     <div className="col bg-primary ">
    //     <h1>Skinvention Services</h1>
    //   </div>
    //     </div>
    // </div>

    // 2nd 

    <div className='container-fluid'>
    <div className="row ps-3  border">
        <div className="col-lg-7 d-flex flex-column justify-content-center">
    <h1 className='fs-1 text-uppercase fw-bolder text-black'>Transforming SKin, Enhancing, beauty</h1>
    <p className='fs-2 pe-5 custom-weight'>Advanced Care for Heathier Skin</p>
        </div>
        <div className="col-lg-5">
            <img src='media\Images\aboutbanner.png' alt='GroupImg' style={{width:'100%'}}/>
        </div>
    </div>

    <div className="row">
        <div className="col title-color ">
        <h1 >Skinvention Services</h1>
      </div>
        </div>
    </div>
  );
}

export default Sbanner
