export const reviews =[{
    name: "Raghav khunteta",
    review : "Dr. Shirin is extremely patient and has in dept knowledge of dermatology. I visited her for pigmentation concern and my skin care routine.",
    date : "2 Sept 2024",
    img: "media/Images/d1.jpg"
},
{name:"Aditya Khuntela",
    review : "DermaSure Skin & Hair Clinic is the best for all your skin and hair problems. Dr Shrin is very knowledgeable and listens to all problems.",
     date : "25 July 2024",
     img: "media/Images/A.png"
},
{
    name : "Shyam Mandal",
    review : "Discussing any skin issues with them. I highly recommend them to anyone in need of great skin doctor !",
     date : "9 June 2024",
       img: "media/Images/s.png"
},
{
    name : "Ronit",
    review : "I visited DermaSure Clinic at a friend's recommendation. I was suffering from acne since 6 years. Dr Shrin made me best clinic Advice ",
     date : "31 January 2024",
       img: "media/Images/boy.jpg"
},
{
    name : "Ritik Durve",
    review : "Nice Treatment and service is also good. Dr. Shrin is the best for skin care issue who solving my problem within seconds. !",
     date : "7 March 2023",
       img: "media/Images/d2.jpg"
}];