import React from 'react'

function AppoinmentPage() {
  return (
    <>
      <div className='container-fluid'>
    <div className="row">
      <form action="" >
     
        <div className="col-lg-6  col-12 rounded-3 mx-auto p-5 m-5 border boxappointment" >
        <h2 className='text-center mb-4'><i class="fa-solid fa-phone-volume"></i> +91 8010689334</h2>
         
          <div class="input-group mb-4">
  <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-user"></i></span>
  <input type="text" class="form-control p-3" placeholder="Name" aria-label="Name" aria-describedby="basic-addon1" required pattern='[a-z][A-Z]'/>
</div>
          <div class="input-group mb-4">
  <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-phone"></i></span>
  <input type="text" class="form-control p-3" placeholder="Mobile" aria-label="Mobile" aria-describedby="basic-addon1"/>
</div>

<div class="input-group mb-4">
  <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-envelope"></i></span>
  <input type="text" class="form-control p-3" placeholder="Email" aria-label="Email" aria-describedby="basic-addon1"/>
</div>
         
         <div class="form-check mx-auto mb-2">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
      <label class="form-check-label" for="flexCheckDefault">
    I agree to T & C
         </label>
        </div>
         <div class="d-grid">
        
  <button class="btn  p-3 fw-bold" type="button" style={{backgroundColor: '#fe539a'}}>Book Appointment</button>
</div>
         
      
        </div>
      </form>
    </div>
    </div>
    </>
  )
}

export default AppoinmentPage
