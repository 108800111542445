import React from 'react'

function Choose() {
  return (
    <div className='container-fluid'>
       <div className="row">
      <div className="col title-color">
        <h1>Why you Choose SkinVention ?</h1>
      </div>
    </div>
    <div className="row  p-5  d-flex justify-content-between align-items-center">

      <div className="col-12 col-md-6 col-lg-2 ">
        <img src="media/Images/Group34.png" alt="personalize" className='chExp centered'/>
        <h3 className='mt-4 text-center'>Personalized Treatment</h3>
      </div>
      <div className="col-12 col-md-6 col-lg-2 ">
        <img src="media\Images\Group33.png" className='chExp centered
' alt="" />
        <h3 className='mt-4 text-center'>Advanced Technology</h3>
      </div>
      <div className="col-12 col-md-6 col-lg-2  ">
      <img src="media\Images\Group31.png" className='chExp centered
' alt="" />
      <h3 className='mt-4 text-center'>Expert Professionals</h3>
      </div>
      <div className="col-12 col-md-6 col-lg-2 ">
      <img src="media\Images\Group35.png" className='chExp centered
' alt="" />
      <h3 className='mt-4 text-center'>High Quality Products</h3>
      </div>
      <div className="col-12 col-md-6 col-lg-2">
      <img src="media\Images\Group32.png" className='chExp centered
' alt="" />
      <h3 className='mt-4 text-center'>Complete <br/>Care</h3>
      </div>

    </div> 
    </div>
  )
}

export default Choose
