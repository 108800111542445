// import React from 'react'
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function Navbar2() {
    let [select, setselect] = useState(0);
    let [hamburgermenu , sethamburgermenu] = useState(false);
  let handleClick = (index)=>{
    setselect(index);
  }
  let Clickhamburger = ()=>{
    sethamburgermenu(!hamburgermenu);
  }
  const menuclass= "menu";
  const activeMenu = "menu selected"
  return (
    <>
        <nav className="navbar navbar-expand-lg p-1 sticky-top"  style={{backgroundColor: '#e9ecef'}}>
  <div className="container-fluid" >
  
   <div className='d-flex'>
   <button className="navbar-toggler" type="button" data-bs-toggle="collapse" onClick={()=> Clickhamburger(!hamburgermenu)} data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

      <span> {hamburgermenu ?<i className="fa-solid fa-xmark"></i>: <i className="fa-solid fa-bars-staggered"></i>  }</span>
    </button>
   <Link className="navbar-brand p-0" to="/" >
      <img src="media/Images/SKINNlogo.png" alt="logo" style={{width:'80%'}} className="logo" />
    </Link>
   
   </div>

   
   
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <form>
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 fw-bold  fs-4" >
        <li class="nav-item me-5">
          <Link className="nav-link active " aria-current="page" to="/" style={{color:'black'}} onClick={()=> handleClick(0)}>
         <span className={select ===0 ? activeMenu : menuclass}>Home</span> </Link>
        </li>
        <li class="nav-item me-5">
          <Link className="nav-link" to="/services"  style={{color:'black'}} onClick={()=> handleClick(1)}>
          <span className={select ===1 ? activeMenu : menuclass}>Services</span></Link>
        </li>
        <li class="nav-item me-5">
          <Link className="nav-link" to="/about"  style={{color:'black'}} onClick={()=> handleClick(2)}>
          <span className={select ===2 ? activeMenu : menuclass}> About</span></Link>
        </li>
        <li class="nav-item me-5">
          <Link className="nav-link" to="/contact"  style={{color:'black'}} onClick={()=> handleClick(3)}>
         <span className={select ===3 ? activeMenu : menuclass}> Contact </span></Link>
        </li>
        <li className="nav-item me-5">
        <Link to='/appointment' className='btn title-color px-3 py-2 fs-4 text-white appointment'>Book&nbsp;An&nbsp;Appointment</Link>
        </li>
        
      </ul>
      </form>
    </div>
  </div>
</nav>
    </>
  )
}

export default Navbar2
