import React from "react";

function Treatment() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col title-color">
          <h1>How to Book Your Customized Treatment at SkinVention</h1>
        </div>
      </div>
      <div className="row mx-5 pt-5 px-2 text-center  mb-0">
        <div className="col-12 col-md-6 col-lg-3">
          <img
            src="media\Images\Group29.png"
            className="img-fluid"
            alt=""
            style={{ width: "70%", height: "70%" }}
          />
          <h3 className='mt-4 '>Consulation</h3>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <img
            src="media\Images\Group30.png"
            className="img-fluid "
            alt=""
            style={{ width: "70%", height: "70%" }}
          />
          <h3 className='mt-4'>Face Mapping</h3>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <img
            src="media\Images\Group27.png"
            className="img-fluid"
            alt=""
            style={{ width: "70%", height: "70%" }}
          />
          <h3 className='mt-4'>Clinic Treatments</h3>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <img
            src="media\Images\Group28.png"
            className="img-fluid "
            alt=""
            style={{ width: "70%", height: "70%" }}
          />
          <h3 className='mt-4'>Home Care</h3>
        </div>
      </div>
    </div>
  );
}

export default Treatment;
