import React from 'react'

function Footer() {
  return (
    <>
    <div className="container">
      <div className="row p-2 my-5">
        <div className="col-md-6 col-12 fs-5 my-3" style={{lineHeight:'3rem'}}>
          <h2 className='mb-4'>Locate Us</h2>
          <div className='d-flex'>
          <img src="media/Images/Location.png" alt="Location" className='pe-3 pt-3 footer-icon' />
          <div>
          <span>Malvya Complex, Vaishali Nagar Rd, Vaishali Nagar, Nagpur, Maharashtra 440017</span>
          </div>
         
          </div>
         <div className='d-flex '>
         <img src="media/Images/Phone.png" alt="Phone" className='pe-3 pt-2 footer-icon '  />
         <div>
         <span> +91 80106 89334 </span>
         </div>
         </div>

         <div className='d-flex'>
         <img src="media/Images/Email.png" alt="Email" className='pe-3 pt-2 footer-icon'  />
         <div >
         <span> skinvention21@gmail.com </span>
         </div>
      
         </div>
          
          <div className='d-flex'>
          <img src="media/Images/Time Span.png" alt="Time Span" className='pe-3 pt-2 footer-icon' />
          <div >
          <span>  Mon - Sun : 11 AM to 6:30 PM </span>
          </div>
          
          </div>
         
        </div>
        <div className="col-md-6 col-12">   
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.5700635728076!2d79.11013957526049!3d21.169501580515274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c72a7cdc2345%3A0x82a28776f6e56aa2!2sMalvya%20Complex!5e0!3m2!1sen!2sin!4v1727064897468!5m2!1sen!2sin" className='rounded' style={{border: '0',width:"100%", height:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
    </>
  )
}

export default Footer
