import React from 'react'
import {reviews} from "../data.js";
import { FaStar } from "react-icons/fa";

function Review() {
  return (
    <>
       <div className='container-fluid'>
    <div className="row">
      <div className="col title-color">
        <h1 >Client Review</h1>
      </div>
    </div>
    <div className="row m-5 p-2" style={{ display: 'flex' }}>
  {reviews.map((rev, index) => (
    <div className="col border" key={index} style={{ margin: '10px', padding: '10px' , height: '260px' }}>
    <div className='d-flex align-items-center'>
    <img src={rev.img} style={{height:'60px', width:'60px', borderRadius: '50%'}} className='p-2'/> 
    <div><h6 style={{padding:0,margin:0}}>{rev.name}  </h6>
    <p style={{padding:0,margin:0}}> {rev.date}</p></div>
    </div>
  <p><FaStar style={{color: 'orange'}}/> <FaStar style={{color: 'orange'}}/> <FaStar style={{color: 'orange'}}/> <FaStar style={{color: 'orange'}}/> <FaStar style={{color: 'orange'}}/>
</p>
     <div> <p style={{height: '160px'}}>{rev.review}
    </p></div>
    </div>
  ))}
</div>
    
    </div>
    </>
  )
}

export default Review
