import React from 'react'
import Cbanner from './Cbanner';
import Form from './Form';


function ContactPage() {
  return (
    <div>
      <Cbanner/>
      <Form/>
     
    </div>
  )
}

export default ContactPage
