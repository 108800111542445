import React from 'react'

function LeftServices({title, description,ImageURL}) {
  return (
    <>
      <div className="container-fluid" >
     
       
        <div className="row py-md-5">
          <div className="col-lg-3 col-12 ">
            {/* <img src="/media/Images/Group.png" alt="" /> */}
            <img src={ImageURL} alt="" style={{width:'85%'}} className='img-fluid mx-5 serImg'   />
          </div>
          <div className="col-lg-9 col-12 px-lg-0 px-5">
            <h1 className='text-black text-md-start text-center'>{title}</h1>
            <h4 className='text-muted mb-4 text-center' >{description} </h4>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeftServices
