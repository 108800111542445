import React from 'react';
import { Link } from 'react-router-dom';

function Consultation() {
  return (
    <div className='container-fluid py-5' style={{backgroundColor: '#e9ecef'}}>
      <div className="row  ps-5">
        <div className="col-md-8 col-12">
            <h2 className=' fs-2'>Struggling with Skin & Hair Issue ?</h2>
        </div>
        <div className="col-md-4 col-12 mt-2 mt-md-0">
            <Link href="#" className='btn title-color py-2 px-md-4 fs-4 text-white' style={{borderRadius:'1rem'}}>Book a Consultation</Link>
        </div>
      </div>
    </div>
  )
}

export default Consultation
