import React from 'react'

function RightServices({title,description,ImageURL}) {
  return (
    <>
       <div className="container-fluid" data-aos="fade-right" data-aos-duration='10'>
       
        <div className="row py-md-5" style={{backgroundColor:'#f5d3e1'}}>
         
         
          <div className="col-lg-4 col-12">
            <img src={ImageURL} alt="" style={{width:'65%'}} className='img-fluid serImg mx-5' />
          </div>
          <div className="col-lg-8 col-12 text-md-end text-center px-lg-0 px-5 order-md-first">
            <h1 className='text-black text-center'>{title}</h1>
            <h4 className='text-muted mb-4'>{description} </h4>
          </div>
        </div>
      </div> 
    </>
  )
}

export default RightServices
