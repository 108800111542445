import React from 'react'

function Cbanner() {
  return (
    <>
    <div className='container-fluid'>
    <div className="row ps-3">
        <div className="col-lg-7 col-12 d-flex flex-column justify-content-center">
   
    <p className='fs-2 pe-5 custom-weight'>Your Journey to Radiant Skin Starts Here</p>
        </div>
        <div className="col-lg-5 col-12 px-3 ">
            <img src='media\Images\contactban.png' alt='GroupImg' style={{width:'100%', height:'100%'}}/>
        </div>
    </div>
    </div>
    </>
  )
}

export default Cbanner
