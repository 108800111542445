import React from 'react'
import Abanner from './Abanner';

import Story from './Story';
import Choose from '../home/Choose';
import Consultation from '../Consultation';

function AboutPage() {
  return (
    <div>
      <Abanner/>
      <Story/>
      <Choose/>
      <Consultation/>
    </div>
  )
}

export default AboutPage
