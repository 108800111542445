import React from 'react'

function Abanner() {
  return (
    <div className='container-fluid'>
      <div className="row ps-3">
      <div className="col-lg-7 col-12 d-flex flex-column justify-content-center">
    <h1 className='fs-1 text-uppercase fw-bolder text-black'>where passion for skincare meets science</h1>
    <p className='fs-2 px-1 custom-weight'>Your Journey to Healthy , Glowing Skin</p>
        </div>
      <div className="col-lg-5 col-12 ">
      <img src="media/Images/img 3.png" alt="" style={{width:'85%'}} />
      </div>
        
      </div>
    </div>
  )
}

export default Abanner
