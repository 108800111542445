import React from 'react';
import './Banner.css';

function Banner() {
  return (
    <div className='container-fluid'>
    <div className="row ps-3">
        <div className="col-lg-6 d-flex flex-column justify-content-center">
    <h1 className='fs-1 text-uppercase fw-bolder text-black'>Where Beauty Meets the Science</h1>
    <p className='fs-2 pe-5 custom-weight'>Get a Customized Solution for all <br/> Skin & Hair Concerns</p>
        </div>
        <div className="col-lg-6 ">
            <img src='media\Images\groupbanner.png' alt='GroupImg' style={{width:'100%', height:'100%'}}/>
        </div>
    </div>
    </div>
  )
}

export default Banner;
